$(function () {
    // filters toggle
    $('#search-filters').addClass('collapse');
    $('.tc-page-up-sidebar-filters-toggle').click(function(){
        $(this).html($(this).find('i').hasClass('fa-plus-circle') ?
            '<i class="fa fa-minus-circle fa-fw" aria-hidden="true"></i> Collapse' :
            '<i class="fa fa-plus-circle fa-fw" aria-hidden="true"></i> Expand');
    });
    // custom message
    var $message = $('#tc-page-up-message');
    var $listings = $('#recent-jobs > table');
    if ($message.length && $listings.length) {
        $message.insertBefore($listings);
    }
});
